<template>
	<header class="relative analytic-requisitions-header white">
		<v-progress-linear
			v-if="loading"
			color="primary"
			absolute
			indeterminate
		></v-progress-linear>
		<v-radio-group
			v-model="device_type"
			mandatory
			row
			hide-details
			class="pa-3 mt-0"
		>
			<v-radio
				v-for="radio of cRadios"
				:key="radio.rValue"
				:value="radio.rValue"
				class="grey lighten-3 text-none subtitle-2 pa-3 rounded"
			>
				<template v-slot:[`label`]>
					<span class="mr-4">{{ radio.name }}</span>
					<h5 class="text-h6 font-weight-regular">{{ radio.value || 0 }}</h5>
					<v-divider vertical class="mx-3" style="display: inline;"></v-divider>
					<h5 class="text-h6 font-weight-regular">{{ radio.percent || 0 }}%</h5>
				</template>
			</v-radio>
		</v-radio-group>
	</header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "AnalyticRequisitionsHeader",

	data() {
		return {
			device_type: null,
			isSet: false,
			radios: [
				{ name: "Все заявки", rValue: null },
				{ name: "От заявителя", rValue: "phone" },
				{ name: "Внутренние", rValue: "inner" },
			],
		};
	},

	computed: {
		...mapGetters({
			loading: "ksk/IS_LOADING_ANALYTIC",
			analytic: "ksk/GET_ANALYTIC",
		}),
		cRadios() {
			if (this.isSet || !this.analytic?.by_created_type) return this.radios;
			this.isSet = true;
			this.radios = this.analytic.by_created_type
				.slice(0, 3)
				.map((e, i) => ({ ...e, rValue: this.radios[i].rValue }));
			return this.radios;
		},
	},

	watch: {
		device_type(x) {
			const device_type = x === 0 ? null : x;
			this.$store.commit("ksk/SET_ANALYTIC_PARAMS", {
				device_type,
			});
			this.$store.dispatch("ksk/load_analytic");
		},
	},
};
</script>

<style></style>
