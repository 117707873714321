<template>
	<section class="analytic-report analytic-report-grid">
		<AnalyticRequisitionsArticle />
		<AnalyticSidebar />
	</section>
</template>

<script>
import AnalyticRequisitionsArticle from "@/components/AnalyticRequisitionsArticle";
import AnalyticSidebar from "@/components/AnalyticSidebar";

export default {
	name: "AnalyticReportRequisitions",

	components: {
		AnalyticRequisitionsArticle,
		AnalyticSidebar,
	},

	beforeDestroy() {
		this.$store.dispatch("ksk/change_analytic_type");
	},
};
</script>

<style></style>
