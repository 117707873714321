<template>
	<article class="analytic-requisitions-article">
		<AnalyticRequisitionsHeader />

		<v-expansion-panels class="mt-4" accordion multiple>
			<AnalyticPanel
				header="Заявки по платности работ"
				footer="Сумма по платным заявкам"
				:items="cPaymentItems"
				:footer-items="cPaymentFooterItems"
			/>
			<AnalyticPanel
				class="mt-4"
				header="Заявки по статусу исполнения работ"
				:items="cTypeItems"
			/>
			<AnalyticPanel
				class="mt-4"
				header="Заявки по категориям"
				:items="cCategoryItems"
			/>
			<AnalyticPanel
				class="mt-4"
				header="Заявки по видам собственности"
				:items="cOwnershipItems"
			/>
		</v-expansion-panels>
	</article>
</template>

<script>
import AnalyticPanel from "@/components/AnalyticPanel";
import AnalyticRequisitionsHeader from "@/components/AnalyticRequisitionsHeader";
import { mapGetters } from "vuex";

export default {
	name: "AnalyticRequisitionsArticle",

	components: {
		AnalyticPanel,
		AnalyticRequisitionsHeader,
	},

	data() {
		return {
			paymentItems: [
				{ name: "Бесплатные", label: "выполненных заявок" },
				{ name: "Платные", label: "выполненных заявок" },
			],
			paymentFooterItems: [{ label: "заявок" }, { label: "на сумму" }],
			typeItems: [
				{ name: "новая" },
				{ name: "принята" },
				{ name: "в работе" },
				{ name: "приостановлена" },
				{ name: "отклонена" },
				{ name: "отменено" },
				{ name: "исполнена" },
				{ name: "просрочена" },
			],
			categoryItems: [
				{ name: "Сантехника" },
				{ name: "Электрика" },
				{ name: "Уборка" },
				{ name: "Отопление" },
				{ name: "Благоустройство" },
				{ name: "Домофон" },
				{ name: "Лифтовое оборудование" },
				{ name: "Видеонаблюдение" },
				{ name: "Ремонтные работы" },
				{ name: "Административные вопросы" },
				{ name: "Дезинфекция и дератизация" },
				{ name: "Пожарная безопасность" },
				{ name: "Прочее" },
				{ name: "Охрана" },
			],
			ownershipItems: [
				{ name: "от квартир" },
				{ name: "от НП" },
				{ name: "от паркоместа" },
			],
		};
	},

	computed: {
		...mapGetters({
			analytic: "ksk/GET_ANALYTIC",
		}),
		cPaymentItems() {
			let res = this.paymentItems;
			if (this.analytic?.by_price) {
				let analytic = this.analytic.by_price;
				res[0].value = analytic.free;
				res[0].percent = analytic.free_percent;
				res[1].value = analytic.paid;
				res[1].percent = analytic.paid_percent;
			}
			return res;
		},
		cTypeItems() {
			let res = this.typeItems;
			if (this.analytic?.by_type) res = this.analytic.by_type;
			return res;
		},
		cCategoryItems() {
			let res = this.categoryItems;
			if (this.analytic?.by_category) res = this.analytic.by_category;
			return res;
		},
		cOwnershipItems() {
			let res = this.ownershipItems;
			if (this.analytic?.by_ownership) res = this.analytic.by_ownership;
			return res;
		},
		cPaymentFooterItems() {
			let res = this.paymentFooterItems;
			if (this.analytic?.by_price) {
				let analytic = this.analytic.by_price;
				res[0].value = analytic.paid;
				res[1].value = analytic.all_price;
			}
			return res;
		},
	},

	created() {
		this.$store.dispatch("ksk/change_analytic_type", "request");
	},

	mounted() {
		this.$store.dispatch("ksk/load_analytic");
	},
};
</script>

<style scoped lang="scss"></style>

<style></style>
