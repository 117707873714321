<template>
	<v-expansion-panel v-bind="$attrs">
		<v-expansion-panel-header class="border-bottom subtitle-1">
			<slot name="header">
				{{ header }}
			</slot>
		</v-expansion-panel-header>
		<v-expansion-panel-content class="pt-4 border-bottom">
			<Linebar
				v-for="item of items"
				:key="item.name"
				:percent="item.percent"
				:value="item.value"
				:label="item.label"
				:name="item.name"
				class="mb-4"
			/>

			<footer
				v-if="footer"
				class="d-flex justify-space-between align-center"
				:class="{ 'border-top pt-4': this.items.length }"
			>
				<span class="subtitle-1 font-weight-light">
					{{ footer }}
				</span>
				<div class="d-flex">
					<AnalyticPanelFooterItem
						v-if="footerItems"
						:label="footerItems[0].label"
						:value="footerItems[0].value"
					/>
					<v-divider
						v-if="hasTwoItems"
						vertical
						class="mx-5"
						style="display: inline;"
					></v-divider>
					<AnalyticPanelFooterItem
						v-if="hasTwoItems"
						:label="footerItems[1].label"
						:value="footerItems[1].value"
					/>
				</div>
			</footer>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import AnalyticPanelFooterItem from "@/components/AnalyticPanelFooterItem";
import Linebar from "@/components/app/Linebar";

export default {
	name: "AnaltyticPanel",

	components: {
		AnalyticPanelFooterItem,
		Linebar,
	},

	props: {
		header: {
			type: String,
			default: "Header",
		},
		items: {
			type: Array,
			required: true,
		},
		footer: {
			type: String,
			default: "",
		},
		footerItems: {
			type: Array,
		},
	},

	computed: {
		hasTwoItems() {
			return this.footerItems?.length > 1;
		},
	},
};
</script>

<style></style>
