<template>
	<div class="d-flex flex-column align-center justify-center">
		<h5 class="text-h5">{{ localeValue }}</h5>
		<span class="subtitle-2 font-weight-light">{{ label }}</span>
	</div>
</template>

<script>
export default {
	name: "AnalyticPanelFooterItem",

	props: {
		value: {
			type: [Number, String],
			default: 0,
		},
		label: {
			type: String,
			required: true,
		},
	},

	computed: {
		localeValue() {
			const value = typeof this.value === "string" ? +this.value : this.value;
			return value.toLocaleString();
		},
	},
};
</script>

<style></style>
